<template>
  <b-card :header="'Track ' + track.TrackSeq + ' - ' + track.Title + ' - ' + track.Attribution" class="mt-4">

    <field 
      :defs="fieldDefs"
      name="ISRC"
      :model="track"
      @change="isrcChange"
      :disabled="isrcChecking"
      :errors="errors" />

    <b-alert :show="isrcNotAvailable != null" variant="danger">ISRC "{{ isrcNotAvailable }}" is not available</b-alert>

    <b-alert :show="useExistingTrack != false" variant="success">
      This ISRC is already known as:<br>
      Provider: {{ useExistingTrack.ProviderDescription }}<br> 
      Sub Provider: {{ useExistingTrack.SubProviderDescription }}<br> 
      Attribution: {{ useExistingTrack.Attribution }}<br> 
      Title: {{ useExistingTrack.Title }} {{ useExistingTrack.TitleVersion }}<br>
      This track will be linked to this release. Please enter a new ISRC if you do not want to link this track to this release.
    </b-alert>

    <template v-if="!useExistingTrack && !isrcChecking">
      <page-snippet name="capitalization" />

      <field 
        :defs="fieldDefs"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        name="TitleLanguage"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="LyricsLanguage"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Title"
        :errors="errors"
        :model="track" />

      <field 
        :defs="fieldDefs"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        name="VersionTitle"
        :model="track"
        :errors="errors" />

      <contributors
        :copyToTracks="trackCount > 1" v-on="$listeners"
        class="mt-5"
        :defs="fieldDefs"
        type="track"
        :model="track"
        @change="updateAttribution"
        :validationErrors="errors" />

      <field 
        :defs="fieldDefs"
        name="Tags.TopLevel"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Tags.RecordingVersion"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Tags.MainGenre"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Tags.SubGenre"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Tags.OtherInfluences"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Tags.Occasion"
        :max="5"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Tags.Tempo"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Tags.ParentalWarningType"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Tags.TrackPriceTier"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Tags.Channels"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="PLine"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="CLine"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="AllowRecompilation"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :boolean="true"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="AllowSingleSales"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :boolean="true"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="BPM"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Attributes.YouTube"
        :model="track"
        :errors="errors" />

      <page-snippet name="public-domain" class="mt-5" />

      <field 
        :defs="fieldDefs"
        name="Attributes.Is Public Domain"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Attributes.MasteredDate"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :date="true"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Attributes.IsRemastered"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        v-if="getAttribute(track, 'IsRemastered') == 'True'"
        :defs="fieldDefs"
        :date="true"
        name="Attributes.ReMasteredDate"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        :defs="fieldDefs"
        name="Attributes.PreviewStartSeconds"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <field 
        v-if="getAttribute(track, 'PreviewStartSeconds') != ''"
        :defs="fieldDefs"
        name="Attributes.PreviewDuration"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :model="track"
        :errors="errors" />

      <!-- Only for Classical tracks -->
      <field 
        v-if="getTag(track, 'TopLevel') == 'Classical'"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :defs="fieldDefs"
        name="WorkTitle"
        :model="track"
        :errors="errors" />

      <field 
        v-if="getTag(track, 'TopLevel') == 'Classical'"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :defs="fieldDefs"
        name="Key"
        :model="track"
        :errors="errors" />

      <field 
        v-if="getTag(track, 'TopLevel') == 'Classical'"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :defs="fieldDefs"
        name="ClassicalCatalogNr"
        :model="track"
        :errors="errors" />

      <field 
        v-if="getTag(track, 'TopLevel') == 'Classical'"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :defs="fieldDefs"
        name="MovementNr"
        :model="track"
        :errors="errors" />

      <field 
        v-if="getTag(track, 'TopLevel') == 'Classical'"
        :copyToTracks="trackCount > 1" v-on="$listeners"
        :defs="fieldDefs"
        name="MovementTitle"
        :model="track"
        :errors="errors" />

      <page-snippet name="rights" class="mt-5" />

      <territory
        v-if="track.Rights"
        class="mt-4"
        :defs="fieldDefs"
        :errors="errors"
        v-model="track.Rights[0].Territory"
        @errors="$emit('errors', { Territory: $event })"
      />

      <field 
        v-if="track.Rights"
        :defs="fieldDefs"
        name="Rights.AllowSubscription"
        :model="track.Rights[0]" />

      <field 
        v-if="track.Rights"
        :defs="fieldDefs"
        name="Rights.AllowAdvertising"
        :model="track.Rights[0]" />

      <field 
        v-if="track.Rights"
        :defs="fieldDefs"
        name="Rights.AllowTransaction"
        :model="track.Rights[0]" />

      <field 
        :defs="fieldDefs"
        name="SourceSubProviderID"
        :errors="errors"
        :model="track" />

      <field 
        :defs="fieldDefs"
        name="SourceTermsetID"
        :errors="errors"
        :model="track" />

      <!--
      <div class="mt-3" v-if="trackCount > 1"><a href="#" @click.prevent="$emit('copyToTracks', 'Territory')">Toepassen op alle tracks</a></div>
      -->
    </template>
  </b-card>
</template>

<script>
  import ReleaseConceptHelper from '@/mixins/ReleaseConceptHelper'
  import { generateAttribution } from '@/functions'

  export default {
    name: 'TrackConcept',
    components: {
      'field': () => import('@/components/ReleaseConceptField'),
      'page-snippet': () => import('@/components/PageSnippet'),
      'contributors': () => import('@/components/ReleaseConceptContributors'), 
      'territory': () => import('@/components/ReleaseConceptTerritory'), 
    },
    mixins: [
      ReleaseConceptHelper
    ],
    data () {
      return {
        territory: '',
        isrcChecking: false,
        isrcNotAvailable: null,
        useExistingTrack: false,
      }
    },
    props: {
      track: Object,
      trackCount: Number,
      fieldDefs: Object,
      errors: Object,
    },
    watch: {
      track () {
        this.isrcChange()
        if (!this.track.Tags) {
          this.$set(this.track, 'Tags', [{
            Type: 'Category', Value: 'Audio'
          }])
        }

        if (!this.track.Rights) {
          this.$set(this.track, 'Rights', [{
            Territory: ''
          }])
        }
      },
    },
    methods: {
      updateAttribution () {
        this.track.Attribution = generateAttribution(this.track)
      },
      isrcChange() {
        let isrc = this.track.ISRC || ''
        isrc = isrc.replace(/[^a-z0-9]/gi, '')
        this.isrcNotAvailable = null
        this.useExistingTrack = false
        this.$set(this.track, 'UseExisting', false)
        this.$set(this.track, 'ReportingCode', '')
        this.$set(this.track, 'ExistingData', null)

        if (!isrc || isrc.length != 12) return

        this.isrcChecking = true
        this.$emit('trackBusy', true)
        
        this.$http
          .get('isrc/'+isrc)
          .then(response => {
            this.isrcChecking = false
            this.$emit('trackBusy', false)
            let data = response.data

            if (!data.Available) {
              this.isrcNotAvailable = isrc
              this.track.ISRC = ''
            } else {
              this.useExistingTrack = data
              this.$set(this.track, 'UseExisting', true)
              this.$set(this.track, 'ReportingCode', data.ReportingCode)
              this.$set(this.track, 'ExistingData', data)
            }
            
          }).catch((error) => {
            if (error.response.status == 404) {
              this.isrcChecking = false
              this.$emit('trackBusy', false)
            } else {
              console.log(error)
            }
          })
      }
    },
    computed: {

    },
    mounted () {
      this.isrcChange()
    }
  }
</script>

<style lang="scss" scoped>

</style>